var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.resizeTable),expression:"resizeTable"}],style:("height:"+_vm.maxHeight+"px;"),attrs:{"fluid":"","flat":"","tile":""}},[_c('v-card-title',{ref:"pageTitle",staticClass:"primary white--text py-3"},[_vm._v(" Line Order #: "+_vm._s(_vm.lineorder.loId)+" "),_c('v-spacer'),_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"append-icon":"mdi-magnify","label":"Search","cols":"2","dark":"","single-line":"","hide-details":""},model:{value:(_vm.docSearch),callback:function ($$v) {_vm.docSearch=$$v},expression:"docSearch"}})],1),_c('v-data-table',{staticClass:"elevation-2 overflow-y:scroll",attrs:{"headers":_vm.headers,"items":_vm.tableItems,"loading":_vm.tableLoading,"search":_vm.docSearch,"height":_vm.tableHeight,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"fixed-header":"","dense":"","id":"docTable"},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"page-count":function($event){_vm.pageCount = $event},"pagination":_vm.updatePaginationText},scopedSlots:_vm._u([{key:"item.docId",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(_vm._s(item.docId))])]}},{key:"item.pubDate",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(_vm._s(_vm.formatDate(item.pubDate)))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(_vm._s(item.type))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.promote",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:[item.promote == "0" ? "notPromoted" : "", "my-1"],attrs:{"depressed":item.promote == "1","color":item.promote == "1" ? "green accent-4" : "light-grey","elevation":"5","small":""},on:{"click":function($event){return _vm.toggleDocPromote(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.promote == "1" ? "mdi-currency-usd" : "mdi-currency-usd-off"))])],1)]}},{key:"footer.prepend",fn:function(){return [_c('v-btn',{on:{"click":function($event){return _vm.$emit('closeExpansion')}}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"7"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-spacer')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }