<template>
  <v-card 
    v-resize="resizeTable"
    :style="`height:`+maxHeight+`px;`"
    fluid 
    flat
    tile
  >
    <v-card-title 
      class="primary white--text py-3"
      ref="pageTitle"
    >
      Line Order #: {{lineorder.loId}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="docSearch"
        append-icon="mdi-magnify"
        label="Search"
        class="pt-0 mt-0"
        cols="2"
        dark
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="tableItems"
      :loading="tableLoading"
      :search="docSearch"
      :height="tableHeight"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      @page-count="pageCount = $event"
      @pagination="updatePaginationText"
      fixed-header
      class="elevation-2 overflow-y:scroll"
      dense
      id="docTable"
    >
      <template #item.docId="{item}">
        <v-row justify="center">{{ item.docId }}</v-row>
      </template>
      <template #item.pubDate="{item}">
        <v-row justify="center">{{ formatDate(item.pubDate) }}</v-row>
      </template>
      <template #item.type="{item}">
        <v-row justify="center">{{ item.type }}</v-row>
      </template>
      <template #item.status="{item}">
        <v-row justify="center">{{ item.status }}</v-row>
      </template>
      <template #item.promote="{item}">
        <v-btn
          :class="[item.promote == `0` ? `notPromoted` : ``, `my-1`]"
          :depressed="item.promote == `1`"
          @click="toggleDocPromote(item)"
          :color="item.promote == `1` ? `green accent-4` : `light-grey`"
          elevation="5"
          small
        >
          <v-icon>{{
            item.promote == `1` ? `mdi-currency-usd` : `mdi-currency-usd-off`
          }}</v-icon>
        </v-btn>
      </template>
      <template #footer.prepend>
        <v-btn @click="$emit('closeExpansion')">
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible=7
        ></v-pagination>
        <v-spacer></v-spacer>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: ["lineorder","maxHeight"],
  data() {
    return {
      docSearch: "",
      tableHeight: 0,
      tableLoading: false,
      lineorderDetails: [],
      tableItems: [],
      page: 1,
      pageCount: 0,
      pageStart: 1,
      pageStop: 10,
      itemsPerPage: 10,
      itemsPerPageOptions: [
        {text: 5, value: 5},
        {text: 10, value: 10},
        {text: 15, value: 15},
        {text: 'All', value: -1}
      ],
      headers: [
        { text: "DocId", value: "docId", divider: true, align: "center", width: "150px" },
        { text: "Title", value: "title", divider: true, align: "center" },
        { text: "PubDate", value: "pubDate", divider: true, align: "center", width: "160px" },
        { text: "Type", value: "contentType", divider: true, align: "center", width: "170px" },
        { text: "Status", value: "status", divider: true, align: "center", width: "140px" },
        { text: "Promote", value: "promote", divider: true, align: "center", width: "98px" }
      ]
    };
  },
  mounted() {
    this.loadDetails();
    // this.resizeTable();
  },
  computed: mapState({
    loDocTableItemsMap() {
      return this.$store.state.medusaCampaigns.loDocTableItemsMap;
    },
    editedLODocs() {
      return this.$store.state.medusaCampaigns.editedLODocs;
    },
    tableItemCount() {
      return this.tableItems.length;
    }
  }),
  methods: {
    formatDate(date) {
      if (date) {
        moment.locale();
        return moment(String(date)).format("ll");
      }
    },
    populateTableFromApi() {
      this.tableLoading = true;
      this.$axios
        .get("/medusa/lineorder/documents", {
          params: {
            loId: this.lineorder.loId
          }
        })
        .then((res) => {
            this.tableLoading = false;
            this.loDocTableItemsMap[this.lineorder.loId] = res.data;
            this.tableItems = res.data;
        }).finally(()=>{
          // Must be in finally block to account for delay in loading tableItems
          this.resizeTable();
        });
    },
    loadDetails() {
      if (this.lineorder.loId) {
        if (!this.loDocTableItemsMap[this.lineorder.loId]) {
          this.populateTableFromApi();
        } else {
          this.tableItems = this.loDocTableItemsMap[this.lineorder.loId];
          this.resizeTable();
        }
      }
    },
    // Keep track of loId of rows that have had promotion changed
    toggleDocPromote(item) {
      item.promote = item.promote == `1` ? `0` : `1`;
      // Add loId key to map tracking doc promotion changes if it doesn't exist
      if (!this.editedLODocs[this.lineorder.loId]) {
        // Must use this.$set and this.$delete to ensure added/deleted properties are reflected in
        // other components using the store
        this.$set(this.editedLODocs, this.lineorder.loId, {});
      }
      // add updated promotion to map if docId doesn't already exist in it, else delete it (change was reverted)
      if (!this.editedLODocs[this.lineorder.loId][item.docId]) {
        this.$set(this.editedLODocs[this.lineorder.loId], item.docId, item.promote);
      } else {
        this.$delete(this.editedLODocs[this.lineorder.loId], item.docId);
        // Delete loId key if no docs have had their promotion changed
        if (Object.keys(this.editedLODocs[this.lineorder.loId]).length == 0) {
          this.$delete(this.editedLODocs, this.lineorder.loId);
        }
      }
    },
    updatePaginationText(paginationObj) {
      this.pageStart = this.page == 1 ? 1 : paginationObj.pageStart;
      this.pageStop = paginationObj.pageStop;
    },
    resizeTable() {
      var pageTitleHeight = this.$refs.pageTitle.getBoundingClientRect().height;
      var tableFooterHeight = document.getElementsByClassName('v-data-footer')[0].getBoundingClientRect().height; //Use boundingClientRec to get exact height with decimals
      this.tableHeight = this.maxHeight - pageTitleHeight - tableFooterHeight;
    }
  }
};
</script>

<style scoped lang="scss">
.notPromoted {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

::v-deep #docTable {
  tbody tr {
    &:nth-of-type(even) {
      background-color: rgba(150, 150, 150, 0.2);
    }
    &:hover {
      background: lighten(gray, 25%);
      cursor: default;
    }
  }
  .v-data-footer {
    padding-top: 2px;
    padding-bottom: 2px;

    // Remove unnecessary footer spacing due to # of rows dropbox
    .v-select  {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 12px;
    }

    .v-data-footer__icons-before, .v-data-footer__icons-after {
      display: none;
    }

    .v-data-footer__pagination {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
</style>
